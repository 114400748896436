
import { mapActions, mapGetters } from 'vuex'
import ModalMixin from '@/mixins/modal'
import Modal from '@/components/elements/Modal'
import { findPixelByVendor } from '~/utils/pixels/helpers'
import { fbqEvent } from '~/utils/pixels/facebook'
import { tiktokEvent } from '~/utils/pixels/tiktok'
import { gtmDataLayer } from '~/utils/ga'
export default {
  mixins: [ModalMixin],
  props: {
    datepicker: {
      type: Boolean,
      default: false
    },
    event: {
      required: true,
      type: Object
    },
    pricezone: {
      required: true,
      type: Object
    },
    currency: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      loading: false,
      addDisabled: false,
      disableRemove: false,
      ticketsLeft: null
    }
  },
  computed: {
    ...mapGetters({
      content: 'cart/content',
      countById: 'cart/countById'
    }),
    count() {
      return this.countById(this.pricezone.id)
    },
    removeDisabled() {
      return this.countById(this.pricezone.id) < 1 || this.disableRemove
    },
    status() {
      return this.pricezone?.status?.toUpperCase()
    },
    options() {
      return this.optionsMap[this.status] || this.optionsMap.DEFAULT
    },
    optionsMap() {
      return {
        SALES_NOT_STARTED: {
          icon: 'blocked',
          gradient: ['#b7b7b7', '#ddd8d7'],
          title: this.$t('ticketselector.SALES_NOT_STARTED.title'),
          subtitle: this.$t('ticketselector.SALES_NOT_STARTED.subtitle'),
          titleColor: '#838383',
          disabled: true
        },
        SALES_ENDED: {
          icon: 'blocked',
          gradient: ['#b7b7b7', '#ddd8d7'],
          title: this.$t('ticketselector.SALES_ENDED.title'),
          subtitle: this.$t('ticketselector.SALES_ENDED.subtitle'),
          titleColor: '#838383',
          disabled: true
        },
        AVAILABLE: {
          icon: 'available',
          gradient: ['#4199df', '#00e8cb'],
          title: this.$t('ticketselector.AVAILABLE.title'),
          subtitle: this.$t('ticketselector.AVAILABLE.subtitle'),
          titleColor: '#838383',
          disabled: false
        },
        SOMEONE_IS_BUYING: {
          icon: 'reserved',
          gradient: ['#E4FF7A', '#AEEB00'],
          title: this.$t('ticketselector.SOMEONE_IS_BUYING.title'),
          subtitle: this.$t('ticketselector.SOMEONE_IS_BUYING.subtitle'),
          titleColor: '#838383',
          disabled: true
        },
        OWN: {
          icon: 'available',
          gradient: ['#4199df', '#00e8cb'],
          title: this.$t('ticketselector.OWN.title'),
          subtitle: this.$t('ticketselector.OWN.subtitle'),
          titleColor: '#838383',
          disabled: false
        },
        SOLDOUT: {
          icon: 'soldout',
          gradient: ['#e83d7f', '#ef7752'],
          title: this.$t('ticketselector.SOLDOUT.title'),
          subtitle: this.$t('ticketselector.SOLDOUT.subtitle'),
          titleColor: '#E83D7F',
          disabled: true
        },
        DEFAULT: {
          icon: 'available',
          gradient: ['#4199df', '#00e8cb'],
          title: this.$t('ticketselector.AVAILABLE.title'),
          subtitle: this.$t('ticketselector.AVAILABLE.subtitle'),
          titleColor: '#838383',
          disabled: false
        }
      }
    }
  },
  watch: {
    count: {
      handler(newVal, oldVal) {
        if (newVal < oldVal) {
          this.addDisabled = false
        }
      }
    }
  },
  mounted() {
    this.ticketsLeft = this.pricezone.available
  },
  methods: {
    ...mapActions({
      fillCart: 'cart/ADD_TICKET',
      deleteFromCart: 'cart/REMOVE_TICKET'
    }),
    onRemove(id) {
      this.disableRemove = true
      this.togglePriceLoader(id, true)
      this.deleteFromCart({
        pricezone_id: id
      })
        .then((response) => {
          this.addDisabled = false
          gtmDataLayer({
            event: 'remove_from_cart',
            ecommerce: {
              currency: this.event.currency,
              value: Number(this.pricezone.price),
              items: [
                {
                  item_id: String(id),
                  item_name: this.event.name,
                  currency: this.event.currency,
                  price: Number(this.pricezone.price),
                  quantity: 1
                }
              ]
            }
          })

          if (response.refresh) {
            this.$emit('fetch-pricezones')
          }

          this.ticketsLeft++
        })
        .finally(() => {
          this.togglePriceLoader(id, false)
          this.disableRemove = false
        })
    },
    togglePriceLoader(id, state) {
      this.loading = state
    },
    onAdd(id) {
      this.addDisabled = true
      this.togglePriceLoader(id, true)

      this.fillCart({
        qty: 1,
        pricezone_id: id
      })
        .then((response) => {
          this.addDisabled = false
          gtmDataLayer({
            event: 'add_to_cart',
            ecommerce: {
              currency: this.currency,
              value: Number(this.pricezone.price),
              items: [
                {
                  item_id: String(id),
                  item_name: this.event.name,
                  currency: this.currency,
                  price: Number(this.pricezone.price),
                  quantity: 1
                }
              ]
            }
          })

          findPixelByVendor(this.event.tracking, 'facebook').map((id) => {
            return fbqEvent(id, 'AddToCart', {
              content_name: this.event.name,
              content_category: 'Event',
              content_ids: [id],
              content_type: 'general admission',
              value: this.pricezone.price,
              currency: this.currency
            })
          })

          findPixelByVendor(this.event.tracking, 'TikTok').map((id) => {
            return tiktokEvent(id, 'AddToCart', {
              contents: [
                {
                  content_id: this.event.id,
                  content_name: this.event.name,
                  content_type: 'general admission',
                  quantity: 1,
                  price: this.pricezone.price
                }
              ],
              content_type: 'product',
              value: this.pricezone.price,
              currency: this.currency
            })
          })

          if (response.refresh) {
            this.$emit('fetch-pricezones')
          }

          this.ticketsLeft--
        })
        .catch((error) => {
          this.addDisabled = true
          this.$emit('fetch-pricezones')

          if (error?.data?.message) {
            this.$modal.show(
              Modal,
              {
                title: 'Oooops!',
                text: error.data.message
              },
              {
                height: 'auto',
                width: this.modalWidthCalc(),
                adaptive: true,
                scrollable: true,
                clickToClose: false
              }
            )
          }
        })
        .finally(() => {
          this.togglePriceLoader(id, false)
        })
    }
  }
}
