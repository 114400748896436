
import { mapGetters } from 'vuex'
export default {
  fetchOnServer: false,
  data: () => ({
    schedule: {},
    id: null
  }),
  async fetch() {
    try {
      const id = parseInt(this.event.id)

      await this.fetchEventSchedule(id)

      this.id = id
    } catch (e) {}
  },
  computed: {
    ...mapGetters({
      event: 'event/event',
      count: 'cart/count'
    })
  },
  watch: {
    '$route.query.date': {
      handler() {
        Object.keys(this.schedule).map((zone) => {
          this.$refs[`zone__tabs__${zone}`][0].selectTab(-1)

          return zone
        })
      }
    }
  },
  methods: {
    async fetchEventSchedule(id) {
      try {
        const data = await this.$axios.$get(`/api/event/${id}/schedule`)
        const schedule = {}
        const result = data.reduce(function (r, a) {
          r[a.time_from.substr(0, 2)] = r[a.time_from.substr(0, 2)] || []
          r[a.time_from.substr(0, 2)].push(a)
          return r
        }, {})

        Object.keys(result).map((item) => {
          schedule[item] = {
            items: result[item],
            active: {
              id: null,
              data: null
            }
          }

          return item
        })

        this.schedule = schedule
      } catch (e) {
        console.log(e)
      }
    },
    async changeTab(index, zone, zoneIndex) {
      try {
        if (index > -1) {
          zone.active.id = zone.items[index].id
          const data = await this.$axios.$get(`api/pricezone/${zone.active.id}`)
          zone.active.data = data

          setTimeout(() => {
            const $el = this.$refs[`zone__${zoneIndex}__item__${index}`][0].$el
            const elBounding = $el.getBoundingClientRect()
            const isInViewport = this.isInViewport($el)
            if (!isInViewport) {
              window.scrollBy({
                top: elBounding.height + 20,
                left: 0,
                behavior: 'smooth'
              })
            }
          }, 100)
        } else {
          zone.active.id = null
          zone.active.data = null
        }
      } catch (e) {}
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) - 100 &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      )
    }
  }
}
