
export default {
  props: {
    title: {
      type: String,
      default: 'Tab'
    },
    available: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isActive: false
    }
  }
}
