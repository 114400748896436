
export default {
  data() {
    return {
      selectedIndex: -1, // the index of the selected tab,
      tabs: [] // all of the tabs
    }
  },
  created() {
    this.tabs = this.$children
  },
  mounted() {
    this.$emit('mounted')
  },
  methods: {
    selectTab(i) {
      if (this.selectedIndex === i) {
        this.selectedIndex = -1
        this.tabs.forEach((tab, index) => {
          tab.isActive = false
        })
      } else {
        this.selectedIndex = i
        this.tabs.forEach((tab, index) => {
          tab.isActive = index === i
        })
      }

      this.$emit('tab:selected', this.selectedIndex)
    }
  }
}
