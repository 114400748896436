
export default {
  name: 'TicketSelector',
  props: {
    item: {
      type: null,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    price: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    disableAdd: {
      type: Boolean,
      default: false
    },
    disableRemove: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasDesctiption() {
      return !!this.$slots.description
    },
    hasPrice() {
      return !!this.$slots.price
    },
    hasHint() {
      return !!this.$slots.hint
    }
  },
  methods: {
    add(item) {
      if (this.disableAdd) return
      this.$emit('add', item)
    },
    remove(item) {
      if (this.disableRemove) return
      this.$emit('remove', item)
    }
  }
}
